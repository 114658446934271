/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/bootstrap@5.1.3/dist/css/bootstrap.min.css
 * - /npm/animate.css@4.1.1/animate.min.css
 * - /npm/phosphor-icons@1.4.2/src/css/icons.min.css
 * - /npm/sweetalert2@11.4.4/dist/sweetalert2.min.css
 * - /npm/izitoast@1.4.0/dist/css/iziToast.min.css
 * - /npm/izimodal-1.6.0@1.6.1/css/iziModal.min.css
 * - /npm/bootstrap-datepicker@1.9.0/dist/css/bootstrap-datepicker.min.css
 * - /npm/bootstrap-timepicker@0.5.2/css/bootstrap-timepicker.min.css
 * - /npm/daterangepicker@3.1.0/daterangepicker.min.css
 * - /npm/select2@4.1.0-rc.0/dist/css/select2.min.css
 * - /npm/ion-rangeslider@2.3.1/css/ion.rangeSlider.min.css
 * - /npm/swiper@8.0.6/swiper-bundle.min.css
 * - /npm/@yaireo/tagify@4.9.8/dist/tagify.min.css
 * - /npm/@yaireo/dragsort@1.2.0/dist/dragsort.css
 * - /npm/filepond@4.30.3/dist/filepond.min.css
 * - /npm/filepond-plugin-image-preview@4.6.10/dist/filepond-plugin-image-preview.min.css
 * - /npm/jssocials@1.5.0/dist/jssocials.min.css
 * - /npm/jssocials@1.5.0/dist/jssocials-theme-plain.min.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
